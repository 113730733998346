import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CreateSurveyInfo, StyledBody, StyledModal } from "./style";
import { BASE_URL } from "../../../utils/config";
import { SaveSurveyAsTemplate, UpdateSurvey } from "../../../redux/constants/apiConstants";
import { toast } from "react-toastify";
import LazyImage from "../../common/LazyImage/LazyImage";
import { ImageUrl } from "../../../utils/constants";
import { withRouter } from "react-router-dom";
import Spinner from "../../common/Spinner";

class CreateSurveyPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:false
    };
  }

  draftSurvey = async () => {
    // e.preventDefault();
    this.setState({ loading: true });
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    const {
      suvreyId,
      companyId
    } = this.props;

    // const focusAreas = exerciseFocusAreas.map((number) => number.toString());
    const surveyDetails = {
      status:"draft",
      company_id: companyId,
    };


    let url = BASE_URL + UpdateSurvey + `/${suvreyId}`;


    await fetch(url, {
      method:"PUT",
      headers: {
        authtoken: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(surveyDetails),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              this.props.history.push("/company/surveys")
            } else {
              toast.error(data.data.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  saveAsTemplate = async () => {
    // e.preventDefault();
    this.setState({ loading: true });
    const AuthToken = localStorage.getItem("AUTH_TOKEN");
    const {
      suvreyId,
      // companyId
    } = this.props;

    let url = BASE_URL + SaveSurveyAsTemplate + `/${suvreyId}`;


    await fetch(url, {
      method:"POST",
      headers: {
        authtoken: AuthToken,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // body: JSON.stringify(surveyDetails),
    })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200 || res.status === 201) {
              this.setState({ loading: false });
              this.props.history.push("/company/surveys");
            } else {
              toast.error(data.data.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  render() {
    const { title, showModal, headerText, updateStep,onClose } = this.props;
    return (
      <StyledModal
        show={showModal}
        onHide={onClose}
        dialogClassName={"info-popup"}
        backdropClassName="drop-back"
        style={{top:"-20%"}}
      >
        <Modal.Header>
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",width:"100%"}}>
            {" "}
            <div><span>{headerText}</span></div>
            <div style={{cursor:"pointer"}}> <img src='/public/images/new_close_icon.png' style={{width:"18px", height:"18px"}}  onClick={onClose}/></div>
          </div>
        </Modal.Header>
        <StyledBody>
          <CreateSurveyInfo>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
              <div className="header">
                <span className="title">
                  <span className="well-done">Well-done! </span> Your survey <span className="survey-name">{title || 'test name'}</span>  is created.
                </span>
              </div>
              <LazyImage div={"image"} src={ImageUrl + "/" + "admin-survey/create-survey-popup.png"}/>
            </div>
            {/* <div>
              <p style={{margin:'0px'}}>
                {" "} {title || ''}?
              </p>
            </div> */}
            {/* <div>
              <img src={ImageUrl + "/" + image} style={{width:'253px', height:'160px',borderRadius:'6px'}}/>
            </div> */}
            <div className="sub-header">{`What do you want to do`}</div>
            <div className="btns">
              <button className="btn1" onClick={()=>{updateStep(3);onClose()}}>Launch this Survey Now</button>
              <div className="or" style={{marginBottom:"15px"}}>or</div>
              <button disabled={this.state.loading} style={{display:"flex", justifyContent:"center", alignItems:"center"}} className="btn2" onClick={()=>!this.state.loading && this.saveAsTemplate()}>{this.state.loading ? (
                <Spinner/>): "Save it for Future Use"}</button>
            </div>
          </CreateSurveyInfo>
        </StyledBody>
      </StyledModal>
    );
  }
}
CreateSurveyPopUp.defaultProps = {
  challenge: { name: "", duration: "", body: "" },
};
CreateSurveyPopUp.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onNoClick:PropTypes.func,
  onYesClick:PropTypes.func,
  deleteref:PropTypes.string,
  headerText:PropTypes.string,
  history:PropTypes.object,
  updateStep:PropTypes.func,
  suvreyId:PropTypes.number,
  companyId:PropTypes.number
};



export default withRouter(connect(null, null)(withTranslation()(CreateSurveyPopUp)));