/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StepsWrapper, Steps } from '../../CreateEvent/style';
import { withTranslation } from 'react-i18next';

class StepsHeading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 1,
      stepsSurvey:[
        {
          name: 'About Survey',
          showArrow: true,
          number: 1
        },
        {
          name: 'Customize Survey',
          showArrow: true,
          number: 2
        },
        {
          name: 'Invite Users',
          showArrow: true,
          number: 3
        },
        {
          name: 'Launch Survey',
          showArrow: false,
          number: 4
        }
      ]

    }
  }

  render() {
    const { stepCount,type } = this.props;
    return (
      <StepsWrapper>
        {this.state.stepsSurvey.map((step, index) =>
          (<Steps key={index} active={index+1<=stepCount?true:false}>
            <div className="wrapper">
              <div className="number" >{step.number}</div>
              <div className="heading"style={{color: (type == "survey" || type == "event" || type == "ondemand" || type === "create-coach" || type==="initiative") && index+1<=stepCount ? "#005C87" : "rgba(0, 92, 135, 0.60)",fontFamily: index+1<=stepCount ? "Rubik-Medium" : "Rubik-Regular",fontSize: "18px",fontWeight: 500,lineHeight: "24px" }}>{this.props.t(step.name)}</div>
            </div>
            {step.showArrow && <div className="arrow">
              <div className="active">
                {index+1<=stepCount?
                  <img src={"/public/images/stepArrowUpdated.svg"} />:
                  <img src={"/public/images/stepArrowUpdated.svg"} />}
              </div>
            </div>}
          </Steps>))}
      </StepsWrapper>
    );
  }
}

StepsHeading.propTypes = {
  history: PropTypes.object,
  stepCount: PropTypes.number,
  type: PropTypes.string,
  isLaunch :PropTypes.bool,
  t: PropTypes.func
};

export default ((withTranslation())(StepsHeading));