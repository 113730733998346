import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { CreateSurveyInfo, StyledBody, StyledModal } from "./style";
import { withRouter } from "react-router-dom";
import { likertScaleIcon, mcqIcon, opinionScaleIcon, shortDescIcon, yesnoIcon } from "../../../utils/icons";
// import { ClosePopUp } from "../../AddRolesPopup/style";

class QuestionReorderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }


  render() {
    // const { title, showModal, headerText, updateStep,onClose } = this.props;
    const {  showModal, headerText,onClose,questions, onDragStart, onDragOver, onDrop } = this.props;
    return (
      <StyledModal
        show={showModal}
        onHide={onClose}
        dialogClassName={"info-popup"}
        backdropClassName="drop-back"
        style={{top:"-20%"}}
      >
        <Modal.Header>
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",width:"100%"}}>
            {" "}
            <div><span>{headerText}</span></div>
            <div style={{cursor:"pointer"}}> <img src='/public/images/new_close_icon.png' style={{width:"18px", height:"18px"}}  onClick={onClose}/></div>
          </div>
        </Modal.Header>
        <StyledBody>
          <CreateSurveyInfo style={{padding:"25px", height:"485px", overflowY:"scroll"}}>
            {questions.map((que,index)=>(
              <div draggable onDragStart={(event) => onDragStart(event, index)}
                onDragOver={onDragOver}
                onDrop={(event) => onDrop(event, index)}  key={index} style={{marginBottom:"10px",borderRadius: "3px",border: "1px solid rgba(0, 92, 135, 0.30)",background: "rgba(247, 245, 235, 0.50)", height:"50px", display:"flex",alignItems:"center", cursor:"grab"}}>
                {que.questionType.name== "short-answer" ? shortDescIcon() : que.questionType.name== "opinion-scale" ? opinionScaleIcon() : que.questionType.name== "multiple-choice" ? mcqIcon() : que.questionType.name== "yes-no" ? yesnoIcon(): que.questionType.name== "likert-scale" ? likertScaleIcon()  : (<div style={{background: "white",width: "40px",height:"40px",borderRadius: "3px"}}></div>)}
                <span style={{marginLeft:"13px",  display: "-webkit-box",webkitLineClamp: 1,webkitBoxOrient: 'vertical',overflow: "hidden",textOverflow: "ellipsis"}}>{que.question}</span></div> 
            ))}

          </CreateSurveyInfo>
        </StyledBody>
      </StyledModal>
    );
  }
}
QuestionReorderPopup.defaultProps = {
  challenge: { name: "", duration: "", body: "" },
};
QuestionReorderPopup.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onNoClick:PropTypes.func,
  onYesClick:PropTypes.func,
  deleteref:PropTypes.string,
  headerText:PropTypes.string,
  history:PropTypes.object,
  updateStep:PropTypes.func,
  questions:PropTypes.array,
  onDragStart:PropTypes.func,
  onDragOver:PropTypes.func, 
  onDrop:PropTypes.func,
  suvreyId:PropTypes.number,
  companyId:PropTypes.number
};



export default withRouter(connect(null, null)(withTranslation()(QuestionReorderPopup)));